<template>
  <v-container id="meeting-detail" fluid tag="section">
    <v-row justify="center">
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message>
      <v-col cols="12" md="10">
        <base-material-card>
          <template v-slot:heading>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
            >
              {{ item.urban_name }}
            </div>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
            >
              {{ item.title }}
            </div>
          </template>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('meeting.urban_renewal')"
                    v-model="item.urban_name"
                    :rules="fieldRules"
                    readonly
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('meeting.meeting_type')"
                    :value="getMeetingType(item.meeting_type)"
                    :rules="fieldRules"
                    readonly
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.meeting_time')"
                    class="purple-input"
                    :rules="fieldRules"
                    v-model="meeting_time_format"
                    @click="openDatetime"
                    readonly
                  />
                  <datetime
                    ref="datetime"
                    input-style="display:none"
                    :rules="fieldRules"
                    v-model="item.meeting_time"
                    type="datetime"
                    format="yyyy/MM/dd HH:mm"
                    :label="$t('meeting.meeting_time')"
                  ></datetime>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="item.meeting_address"
                    :label="$t('meeting.meeting_address')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    :rules="fieldRules"
                    :disabled="item.meeting_type!=2"
                    :label="$t('meeting.attendance_owner_count')"
                    v-model="item.attendance_owner_count"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.votable_count"
                    :rules="fieldRules"
                    :disabled="item.meeting_type!=2"
                    :label="$t('meeting.votable_count')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.audiences_count"
                    readonly
                    :label="$t('meeting.audiences_count')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_land_rate_numerator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_land_rate_numerator"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_land_rate_denominator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_land_rate_denominator"
                    ><v-icon slot="prepend">
                      mdi-slash-forward
                    </v-icon></v-text-field
                  >
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_land_area')"
                    class="purple-input"
                    type='number'
                    v-model="landAreaFormat"
                    readonly
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_building_rate_numerator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_building_rate_numerator"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_building_rate_denominator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_building_rate_denominator"
                    ><v-icon slot="prepend">
                      mdi-slash-forward
                    </v-icon></v-text-field
                  >
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_building_area')"
                    class="purple-input"
                    type='number'
                    v-model="buildingAreaFormat"
                    readonly
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_owner_rate_numerator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_owner_rate_numerator"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_owner_rate_denominator')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_owner_rate_denominator"
                  ><v-icon slot="prepend">
                    mdi-slash-forward
                  </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('meeting.required_owner_count')"
                    class="purple-input"
                    type='number'
                    v-model="item.required_owner_count"
                    readonly
                  />
                </v-col>

                <v-col cols="12">
                  <v-simple-table fixed-header height="190px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left text-h4">
                            {{ $t("meeting.audiences") }}
                          </th>
                          <th class="text-right">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              @click="newAudiences()"
                            >
                              <v-icon left>mdi-pencil-plus</v-icon>
                              {{ $t("meeting.newAudiences") }}
                            </v-btn>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(audience, index) in audiences_list">
                          <tr>
                            <td>
                              <v-text-field
                                v-model="audience.name"
                              ></v-text-field>
                            </td>
                            <td>
                              <v-btn
                                color="red"
                                class="mx-2"
                                small
                                @click="removeAudiences(index)"
                                >{{ $t("common.delete") }}</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <p class="mb-2 v-label theme--light">{{ $t("meeting.notice_info") }}：</p>
                </v-col>
                <v-card class="mt-0" elevation="5">
                  <v-row class="px-2">
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="4" class="pr-0 py-0">
                          <v-text-field
                            v-model="notice_word"
                            placeholder="○○○○"
                            @change="setNotice()"
                            :label="$t('meeting.notice_no')"
                          ></v-text-field>
                        </v-col>
                        <v-col class="px-0 py-0">
                          <v-text-field
                            label="字第"
                            single-line
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-0 py-0">
                          <v-text-field
                            v-model="notice_number"
                            placeholder="○○○○○○"
                            @change="setNotice()"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pl-0 py-0">
                          <v-text-field
                            label="號"
                            single-line
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.host"
                        :label="$t('meeting.host')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.contact_name"
                        :label="$t('meeting.contact_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.contact_phone"
                        :label="$t('meeting.contact_phone')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.annex"
                        :label="$t('meeting.annex')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left text-h4" style="width:80%">
                                {{ $t("meeting.description") }}
                              </th>
                              <th class="text-right">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  @click="newDescription()"
                                >
                                  <v-icon left>mdi-pencil-plus</v-icon>
                                  {{ $t("meeting.newDescription") }}
                                </v-btn>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(description, index) in description"
                            >
                              <tr>
                                <td>
                                  <v-textarea
                                    v-model="description.text"
                                    :label="getIndexText(index+1)"
                                    :rules="fieldRules"
                                    no-resize
                                  ></v-textarea>
                                </td>
                                <td>
                                  <v-btn
                                    color="red"
                                    class="mx-2"
                                    small
                                    @click="removeDescription(index)"
                                    >{{ $t("common.delete") }}</v-btn
                                  >
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- <v-col class="text-right">
                  <v-dialog
                    v-model="dialog_owner"
                    max-width="500px"
                    @click:outside="closeOwner"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark class="mb-2" v-on="on">
                        <v-icon left>mdi-pencil-plus</v-icon>
                        {{ $t("meeting.removeOwner") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{
                          $t("meeting.removeOwner")
                        }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row dense>
                            <v-data-table
                              v-model="selected"
                              :headers="ownerHeaders"
                              :items="owners"
                              :items-per-page="5"
                              :loading="ownerLoading"
                              multi-sort
                              show-select
                            ></v-data-table>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeOwner">
                          {{ $t("common.cancel") }}
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="resetOwner">
                          {{ $t("common.save") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    class="purple-input"
                    :label="$t('meeting.invited_owner_id_list')"
                    v-model="item.invited_owner_name_list"
                    readonly
                  />
                </v-col> -->

                <v-col cols="12" class="text-right">
                  <div class="d-flex">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="navy"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left>mdi-download</v-icon>
                          {{ $t("meeting.signInList") }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group
                          v-model="selectedItem"
                          color="navy"
                        >
                          <v-list-item
                            v-for="(item, index) in output_type"
                            :key="index"
                            @click="getSignIn(item.type)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <v-btn color="#2699FB" @click="getNotice()">
                      <v-icon left>mdi-download</v-icon>{{ $t("meeting.notice") }}
                    </v-btn>
                    <v-btn color="aqua" class="ml-auto mr-2" @click="back()">{{
                      $t("common.back")
                    }}</v-btn>
                    <v-btn color="success" class="mr-0" @click="patchApi()">{{
                      $t("common.save")
                    }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: (vm) => ({
    dialog_message: false,
    valid: true,
    audiences_list: [],
    description: [],
    item: {
      urban_renewal: { name: null },
      meeting_type: null,
      meeting_time: null,
      attendance_owner_count: null,
      required_owner_count: null,
      required_owner_rate_numerator: null,
      required_owner_rate_denominator: null,
      required_building_area: null,
      required_building_rate_numerator: null,
      required_building_rate_denominator: null,
      required_land_area: null,
      required_land_rate_numerator: null,
      required_land_rate_denominator: null,
      invited_owner_id_list: null,
      invited_owner_name_list: null,
      votable_count: null,
      audiences_count: 0,
      audiences: null,
      exclude_list: null,
      meeting_address: null,
      notice_no:null,
      host:null,
      contact_name:null,
      contact_phone:null,
      annex: null
    },
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    owners: [],
    totalOwners: 0,
    ownerLoading: false,
    selected: [],
    dialog_owner: false,
    notice_word: null,
    notice_number: null,
    ownerHeaders: [
      {
        sortable: false,
        text: vm.$i18n.t("owner.owner_name"),
        value: "owner_name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.building_area"),
        value: "building_info.building_area",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land.land_area"),
        value: "land_info.land_area",
      },
    ],
    meeting_time_format: null,
    message: {
      title: "",
      text: "",
    },
    meetingTypeList: [
      {
        value: 1,
        text: vm.$i18n.t("meeting.member"),
      },
      {
        value: 2,
        text: vm.$i18n.t("meeting.director"),
      },
      {
        value: 3,
        text: vm.$i18n.t("meeting.publicHearing"),
      },
    ],
    selectedItem: null,
    output_type: [
      {
        title: vm.$i18n.t("meeting.signInList_show"),
        type: "show"
      },
      {
        title: vm.$i18n.t("meeting.signInList_hide"),
        type: "hide"
      }
    ]
  }),
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
    landAreaFormat(){
      return CommonUtil.areaFormat(this.item.required_land_area)
    },
    buildingAreaFormat(){
      return CommonUtil.areaFormat(this.item.required_building_area)
    },
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.ownerLoading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.item_id +
        "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.ownerLoading = false;
          currentObj.setOwners(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchApi() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let required_valid = this.checkRateInput()
      if (!required_valid) {
        this.message.title = this.$t("save-fail");
        this.message.text = "請檢查成會比例";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let voteable_valid = this.checkVoteable()
      if (!voteable_valid) {
        this.message.title = this.$t("save-fail");
        this.message.text = "請檢查出席/納入計算人數";
        this.dialog_message = !this.dialog_message;
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.item.id +
        "/";

      const jsonData = {
        meeting_time: this.item.meeting_time,
        meeting_type: this.item.meeting_type,
        attendance_owner_count: this.item.attendance_owner_count,
        required_owner_count: this.item.required_owner_count,
        required_owner_rate_numerator: this.item.required_owner_rate_numerator,
        required_owner_rate_denominator: this.item.required_owner_rate_denominator,
        required_building_area: this.item.required_building_area,
        required_building_rate_numerator: this.item.required_building_rate_numerator,
        required_building_rate_denominator: this.item.required_building_rate_denominator,
        required_land_area: this.item.required_land_area,
        required_land_rate_numerator: this.item.required_land_rate_numerator,
        required_land_rate_denominator: this.item.required_land_rate_denominator,
        invited_owner_id_list: this.item.invited_owner_id_list,
        votable_count: this.item.votable_count,
        audiences_count: this.item.audiences_count,
        audiences: this.item.audiences,
        exclude_list: this.item.exclude_list,
        meeting_address: this.item.meeting_address,
        notice_no: this.item.notice_no,
        host: this.item.host,
        contact_name: this.item.contact_name,
        contact_phone: this.item.contact_phone,
        description: JSON.stringify(this.description),
        annex: this.item.annex
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          alert("更新成功。");
          currentObj.back();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    setOwners(data) {
      this.item = data
      if(data.notice_no != null){
        let notice_strList = data.notice_no.split("字第")
        this.notice_word = notice_strList[0]
        this.notice_number = notice_strList[1].slice(0, -1)
      }
      if(data.description != null){
        this.description = JSON.parse(data.description)
      }
      // this.item = data.meeting;
      // this.owners = Object.assign([], data.results);
      // this.totalOwners = this.owners.length;
      // let ownerIds = [];
      // let ownerIdList = this.item.invited_owner_id_list.split(",");
      // ownerIdList.forEach((element) => {
      //   ownerIds.push(parseInt(element));
      // });
      // let ownerNames = [];
      // this.owners.forEach((owner) => {
      //   if (ownerIds.indexOf(owner.id) == -1) {
      //     this.selected.push(owner);
      //   } else {
      //     ownerNames.push(owner.owner_name);
      //   }
      // });
      // // this.item.votable_count = ownerIds.length;
      // // this.item.attendance_owner_count = this.totalOwners;
      // this.item.invited_owner_name_list = ownerNames.toString();
      // // this.item.invited_owner_id_list = ownerIds.toString();
    },
    closeOwner() {
      this.dialog_owner = false;
      this.ownerLoading = false;
    },
    resetOwner() {
      let selected_ids = [];
      this.selected.forEach((element) => {
        selected_ids.push(element.id);
      });
      let ownerNames = [];
      let ownerIds = [];
      this.owners.forEach((item) => {
        if (selected_ids.indexOf(item.id) == -1) {
          ownerNames.push(item.owner_name);
          ownerIds.push(item.id);
        }
      });
      this.item.attendance_owner_count = ownerIds.length;
      this.item.invited_owner_name_list = ownerNames.toString();
      this.item.invited_owner_id_list = ownerIds.toString();
      this.closeOwner();
    },
    back(item) {
      this.$router.push({
        name: "MeetingTables",
      });
    },
    openDatetime() {
      this.$refs.datetime.isOpen = true;
    },
    getMeetingType(meeting_type) {
      let text = "";
      this.meetingTypeList.forEach((element) => {
        if (element["value"] == meeting_type) {
          text = element["text"];
        }
      });
      return text;
    },
    setAudiences(){
      if(!this.item.audiences){
        return
      }
      let audiences_list = this.item.audiences.split(',')
      audiences_list.forEach(element=>{
        let new_item = {
          name: element,
        };
        this.audiences_list.push(new_item);
      })
    },
    newAudiences() {
      let new_item = {
        name: "",
      };
      this.audiences_list.push(new_item);
    },
    removeAudiences(item_index) {
      this.audiences_list.splice(item_index, 1);
    },
    checkRateInput(){
      let required_valid = true
      if(this.item.required_owner_rate_numerator != null && this.item.required_owner_rate_numerator.length == 0){
        this.item.required_owner_rate_numerator = null
      }
      if(this.item.required_owner_rate_denominator != null && this.item.required_owner_rate_denominator.length == 0){
        this.item.required_owner_rate_denominator = null
      }
      if(this.item.required_building_rate_numerator != null && this.item.required_building_rate_numerator.length == 0){
        this.item.required_building_rate_numerator = null
      }
      if(this.item.required_building_rate_denominator != null && this.item.required_building_rate_denominator.length == 0){
        this.item.required_building_rate_denominator = null
      }
      if(this.item.required_land_rate_numerator != null && this.item.required_land_rate_numerator.length == 0){
        this.item.required_land_rate_numerator = null
      }
      if(this.item.required_land_rate_denominator != null && this.item.required_land_rate_denominator.length == 0){
        this.item.required_land_rate_denominator = null
      }
      if(this.item.required_owner_rate_numerator != this.item.required_owner_rate_denominator){
        if(this.item.required_owner_rate_numerator==null||this.item.required_owner_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      if(this.item.required_building_rate_numerator != this.item.required_building_rate_denominator){
        if(this.item.required_building_rate_numerator==null||this.item.required_building_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      if(this.item.required_land_rate_numerator != this.item.required_land_rate_denominator){
        if(this.item.required_land_rate_numerator==null||this.item.required_land_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      return required_valid
    },
    checkVoteable(){
      let valid = true;
      if(this.item.votable_count > this.item.attendance_owner_count){
        valid = false;
      }
      return valid
    },
    setNotice(){
      this.item.notice_no = this.notice_word + "字第" + this.notice_number + "號";
    },
    newDescription() {
      let new_item = {
        text: "",
      };
      this.description.push(new_item);
    },
    removeDescription(item_index) {
      this.description.splice(item_index, 1);
    },
    getIndexText(index) {
      return CommonUtil.getIndexText(index)
    },
    getSignIn(show_type) {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.item.id +
        "/getSignIn/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          show_type: show_type
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = currentObj.item.urban_name + "_" + currentObj.item.title + "簽到冊_"+ date_str +".docx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          currentObj.selectedItem = null;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
          currentObj.selectedItem = null;
        });
    },
    getNotice() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.item.id +
        "/getNotice/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = currentObj.item.urban_name + "_" + currentObj.item.title + "會議通知_"+ date_str +".docx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          currentObj.selectedItem = null;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
          currentObj.selectedItem = null;
        });
    }
  },
  watch: {
    ["item.meeting_time"](val) {
      if (val) {
        this.meeting_time_format = this.TransDate(Date.parse(val));
      }
    },
    audiences_list:{
      handler: function(){
        let audiences_list = []
        this.audiences_list.forEach(element=>{
          if(element.name.trim().length!=0){
            audiences_list.push(element.name)
          }
        })
        this.item.audiences_count = audiences_list.length
        this.item.audiences = audiences_list.toString()
      },
      deep: true
    }
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.MeetingDetail"));
    this.item_id = this.$route.query.item;
    this.setAudiences();
    this.getApi();
  },
};
</script>
<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: navy
</style>